<template>
  <div
    class="WashroomscoreBox item-b"
    v-loading="showLoad"
    element-loading-text="拼命加载中"
  >
    <FormItem
      :formList="formList"
      :btn="btn"
      @search="onSearch"
      @export="onExport"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
      @changeRadio="radioHandle"
    />
    <Table
      ref="table"
      :tableList="tableList"
      :tableData="tableData"
      @currentNum="current"
      @operation="onHandle"
      @toPublicDetails="toPublic"
      @cellclick="goRecord"
      @reset="onReset"
      :total="total"
      :navtor="true"
    />
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="boxTitle">{{ tongjititle }}</div></el-col
        >
        <el-col :span="6"
          ><div>
            <el-date-picker
              class="custom-input"
              v-model="value3"
              value-format="yyyy"
              format="yyyy"
              type="year"
              :placeholder="timer"
              :picker-options="pickerOptions"
            >
            </el-date-picker></div
        ></el-col>
        <el-col :span="2"
          ><div>
            <el-button
              type="primary"
              class="item-primary"
              @click="searchClick(value3)"
              >查询</el-button
            >
          </div></el-col
        >
        <el-col :span="2"
          ><div>
            <el-button class="item-info" @click="logReset">重置</el-button>
          </div></el-col
        >
      </el-row>
      <div class="box">
        <div class="boxOne">
          <div class="boxOne1">
            <div class="boxOneTitle">
              <span class="boxOneTest">氨气报警次数统计表</span
              ><span class="boxOneNum">
                <div class="gongce">
                  <div>
                    <span class="women"></span>
                    <span>女厕</span>
                  </div>
                  <div>
                    <span class="men"></span>
                    <span>男厕</span>
                  </div>
                </div>
              </span>
            </div>
            <Echart :option="ChartData1" style="height: 100%"></Echart>
          </div>
          <div class="boxOne2">
            <div class="boxOneTitle">
              <span class="boxOneTest">硫化氢报警次数统计表</span
              ><span class="boxOneNum">
                <div class="gongce">
                  <div>
                    <span class="women"></span>
                    <span>女厕</span>
                  </div>
                  <div>
                    <span class="men"></span>
                    <span>男厕</span>
                  </div>
                </div>
              </span>
            </div>
            <Echart :option="ChartData2" style="height: 90%"></Echart>
          </div>
        </div>
        <div class="boxTwo">
          <div class="boxTwo1">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月得分</span
              ><span class="boxOneNum">{{ bingFen.score || 0 }}分</span>
            </div>
            <Echart :option="ChartData3"></Echart>
          </div>
          <div class="boxTwo2">
            <div class="boxOneTitle">
              <span class="boxOneTest">当月公厕分数排名</span
              ><span class="boxOneNum">第{{ Listcount }}名</span>
            </div>
            <div class="paiMing">
              <div class="paiBox">
                <div
                  class="paiMian"
                  v-for="(item, i) in tongLists"
                  :key="i"
                  @click="show(item.toiletId, value3)"
                >
                  <div v-if="i == 0">
                    <span><img src="../assets/img/paione.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 1">
                    <span><img src="../assets/img/paithree.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 2">
                    <span><img src="../assets/img/paitwo.png" alt="" /></span>
                  </div>
                  <div class="paiNum" v-else>
                    <span> {{ i + 1 }}</span>
                  </div>
                  <div class="paiText">{{ item.toiletName }}</div>
                  <div class="shu1" v-show="i > 0"></div>
                  <div class="shu2" v-show="i > 2"></div>
                  <div class="shu3" v-show="i > 3"></div>
                </div>
              </div>
            </div>
            <div class="dang"></div>
            <div></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import Echart from "./Echart";
import axios from "@/request";
import axiosT from "axios";
export default {
  name: "Washroomscore",
  components: { Echart },
  data() {
    return {
      timer: "",
      eaitData: {},
      dialogVisible: false,
      value3: "", //查询日期
      showLoad: true,
      ammoniaGas: "", //氨气
      ydrothion: "", //硫化氢
      curDay: "",
      formList: [
        {
          props: "toiletName",
          el: "el-input",
          label: "公厕名称",
          placeholder: "请输入公厕名称",
        },
        {
          props: "deptName",
          el: "el-input",
          label: "项目部名称",
          placeholder: "请输入项目部名称",
        },
        // {
        //   props: 'dateType',
        //   noClear:true,
        //   el:'el-radio-group',
        //   align:'right',
        //   options:[
        //     {
        //       value:'1',
        //       name:'月度'
        //     },
        //     {
        //       value:'2',
        //       name:'年度'
        //     }
        //   ]
        // }
      ],
      btnObj: {
        search: "查询",
        export: "导出",
        reset: "重置",
      },
      btn: "search,export,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          prop: "toiletName",
          label: "公厕名称",
          width: "",
        },
        {
          prop: "score",
          label: "公厕得分",
          width: "",
        },
        {
          label: "氨气报警(次）",
          width: "",
          multistage: true,
          options: [
            {
              prop: "manAmmoniaGasAlerts",
              label: "男厕",
              width: "",
              color(val) {
                return "#3B7FFF";
              },
            },
            {
              prop: "womanAmmoniaGasAlerts",
              label: "女厕",
              width: "",
              color(val) {
                return "#F94343";
              },
            },
          ],
        },
        {
          label: "硫化氢报警(次)",
          width: "",
          multistage: true,
          options: [
            {
              prop: "manHydrogenAlerts",
              label: "男厕",
              width: "",
              color(val) {
                return "#3B7FFF";
              },
            },
            {
              prop: "womanHydrogenAlerts",
              label: "女厕",
              width: "",
              color(val) {
                return "#F94343";
              },
            },
          ],
        },
        {
          prop: "projectDeptName",
          label: "管养项目部",
          width: "",
        },
        {
          prop: "staffName",
          label: "负责人",
          width: "",
        },
        {
          prop: "staffPhone",
          label: "联系电话",
          width: "",
        },
        {
          prop: "scoreTimeStr",
          label: "评分月度",
          width: "",
        },
        {
          prop: "scoreType",
          label: "总体评分",
          width: "",
        },
        {
          prop: "op",
          label: "操作",
          width: "150",
          btn: [
            {
              type: "text",
              name: "查看统计",
              prop: "edit",
            },
          ],
        },
      ],
      tableData: [],
      tongLists: [], //排名
      Listcount: "", //排名
      bingFen: {}, //饼图得分
      ChartData1: null, //折线
      ChartData2: null,
      ChartData3: null,
      page: 1,
      size: 10,
      total: 0,
      search: {
        toiletName: "",
        dateType: "1",
        deptName: "",
      },
      tongjititle: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
    };
  },
  mounted() {
    const time = new Date();
    this.timer = time.getFullYear() + "";
    this.value3 = this.timer;
    this.getData(this.page, this.size, this.search);
    this.curDay = `${time.getFullYear()}-${
      time.getMonth() + 1
    }-${time.getDate()}`;
    // this.getWashRoomChartData();
    this.getData(this.page, this.size, this.search);
  },
  methods: {
    handleClose(done) {
      done();
    },
    getData(page, size, val) {
      this.showLoad = true;
      axios
        .get(`/toiletAssess/page/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.list;
          this.total = res.total;
          this.showLoad = false;
        });
    },
    goRecord(data) {
      if (data.column.property == "toiletName") {
        console.log(data);
        this.$router.push({
          path: "/alarmrecord",
          query: {
            params: data.row.toiletName,
            paths: "/alarmrecord",
          },
        });
      }
    },
    onSearch(val) {
      console.log(val);
      this.search = val;
      this.getData(this.page, this.size, val);
    },
    searchClick(val) {
      console.log(val);
      this.show(this.eaitData.toiletId, val);
    },
    radioHandle(val) {
      this.search = val;
      this.getData(this.page, this.size, val);
      if (val.dateType == 1) {
        this.tableList[8].label = "评分月度";
      } else if (val.dateType == 2) {
        this.tableList[8].label = "评分年度";
      }
    },
    toPublic() {
      console.log(11111);
    },
    onExport(val) {
      console.log(val);
      this.$export({
        url: "/toiletAssess/export",
        method: "get",
        params: { ...this.search },
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a");
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "公厕绩效考核.xlsx";
        link.click();
        URL.revokeObjectURL(objectUrl); // 释放内存
      });
    },
    onReset(val) {
      console.log(val);
      this.dialogVisible = false;
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, val);
    },

    onHandle(data) {
      console.log(data);
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == "del") {
        // this.del(data.data);
      } else if (data.opVal == "edit") {
        this.edit(data.data);
      }
    },
    edit(data) {
      console.log(data);
      this.tongjititle = data.toiletName;
      this.eaitData = data;
      this.show(data.toiletId, data.year);
      this.dialogVisible = true;
    },

    // 重置
    logReset() {
      this.value3 = new Date().getFullYear() + "";
      this.show(this.eaitData.toiletId, this.eaitData.year);
    },
    current(val) {
      this.page = val;
      this.getData(this.page, this.size, this.search);
    },
    show(toiletId, year) {
      axios
        .get(`/detailsCount/alarmToiletCount?toiletId=${toiletId}&year=${year}`)
        .then((res) => {
          this.tongLists = res.toiletScoreVoList;
          this.bingFen = res.toiletScore;
          if (this.tongLists.length > 0) {
            let kong = this.tongLists.find((e, index) => {
              if (toiletId == e.toiletId) {
                this.Listcount = index + 1;
                return e;
              }
            });
            if (kong == undefined) {
              this.Listcount = this.tongLists.length+1;
            } else {
              this.tongjititle = kong.toiletName;
            }
          }

          let chartData1 = this.getChartData();
          let chartData2 = this.getChartData();
          let chartData3 = this.yuanLine();
          let xMax = Math.max.apply(
            null,
            res.monthH2S.womanCount.concat(res.monthH2S.manCount)
          );
          let yMax = Math.max.apply(
            null,
            res.monthNH3.womanCount.concat(res.monthNH3.manCount)
          );
          console.log(xMax, yMax);
          //  let yMax = Math.max(...[xnumMax, ynumMax]);
          // 氨气报警次数统计表
          console.log(chartData1);
          chartData1.series[0].data = res.monthH2S.manCount;
          chartData1.series[1].data = res.monthH2S.womanCount;
          if (xMax > 5) {
            chartData1.yAxis.max = xMax;
          } else {
            chartData1.yAxis.max = 5;
          }

          this.ChartData1 = chartData1;
          //硫化氢报警次数统计表
          chartData2.series[0].data = res.monthNH3.manCount;
          chartData2.series[1].data = res.monthNH3.womanCount;

          if (yMax > 5) {
            chartData2.yAxis.max = yMax;
          } else {
            chartData2.yAxis.max = 5;
          }
          this.ChartData2 = chartData2;
          //超载处理时长
          chartData3.series[0].data[0].value = res.toiletScore.score
            ? res.toiletScore.score
            : 0;
          chartData3.series[0].data[0].name = "得分";
          chartData3.series[0].data[1].value =
            res.toiletScore.total - res.toiletScore.score;
          chartData3.series[0].data[1].name = "未得";
          this.ChartData3 = chartData3;
          console.log(chartData3);
        });
    },

    // async getWashRoomChartData(time = "2021-5-13", id = 1, type = 1) {
    //   const query = {
    //     params: {
    //       dateTime: time,
    //       toiletId: id,
    //       type,
    //     },
    //   };
    //   let data = await axios.get("/toiletRealDay/findToilet/dayReport", query);
    //   if (data) {
    //     let xLabel = data.manToiletDayReport.map((item) => {
    //       return item.hour;
    //     });
    //     let manValue = data.manToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let womenValue = data.womanToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let selTime = new Date(...time.split("-")).getTime();
    //     let curTime = new Date(...this.curDay.split("-")).getTime();
    //     if (selTime > curTime) {
    //       manValue = [];
    //       womenValue = [];
    //     }
    //     if (selTime === curTime) {
    //       let hour = new Date().getHours();
    //       let index = xLabel.indexOf(hour) + 1;
    //       manValue.splice(index);
    //       womenValue.splice(index);
    //     }
    //     console.log(manValue);
    //     console.log(womenValue);
    //     let xnumMax = Math.max(...manValue);
    //     let ynumMax = Math.max(...womenValue);
    //     let xMax = Math.max(...[xnumMax, ynumMax, this.ammoniaGas]);
    //     let yMax = Math.max(...[xnumMax, ynumMax, this.ydrothion]);
    //     let chartData = this.getChartData();
    //    // chartData.xAxis.data = xLabel;
    //     chartData.series[0].data = manValue;
    //      chartData.series[1].data = womenValue
    //     if (type === 1) {
    //       let xAxis = chartData;

    //       //xAxis.series[1].markLine.data[0].yAxis=this.ammoniaGas
    //       //  xAxis.series[1].markLine.label.formatter=this.ammoniaGas
    //       // splitNumber: 2,
    //       xAxis.yAxis.max = xMax;
    //       // xAxis.yAxis.interval=0.1
    //       this.ChartData1 = chartData;
    //      this.ChartData2 = chartData
    //       this.ChartData3 = this.yuanLine();
    //     } else {
    //       let yAxis = chartData;
    //       //  yAxis.series[1].markLine.data[0].yAxis=this.ydrothion
    //       // yAxis.series[1].markLine.label.formatter=this.ydrothion
    //       // splitNumber: 2,
    //       yAxis.yAxis.max = yMax;
    //       //yAxis.yAxis.interval=0.1
    //       this.ChartData2 = yAxis;
    //       console.log(chartData);
    //     }
    //   }
    // },
    // 饼图
    yuanLine() {
      return {
        // legend: {
        //   orient: "vertical",
        //   right: 10,
        //   top: 30,
        //   formatter: function (name) {
        //     return name;
        //   },
        // },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}分({d}%)",
          position: ["0%", "0%"],
        },
        color: ["#77C76E", "#EB7F67"],
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "当月得分",
            // label: {
            //   //去除饼图的指示折线label
            //   normal: {
            //     show: true,
            //     position: "inside",
            //     formatter: "{b}:{d}%",
            //   },
            // },
            type: "pie",
            radius: [40, 70],
            center: ["50%", "50%"],
            label: {
              normal: {
                formatter: "{c}分",
                textStyle: {
                  fontWeight: "normal",
                  fontSize: 15,
                },
              },
            },
            data: [
              { value: 98, name: "100" },
              { value: 98, name: "100" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    //柱状图
    zhuLine() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: 45,
          right: 40,
          bottom: 50,
          top: 15,
        },
        xAxis: [
          {
            name: "月",
            nameTextStyle: {
              color: "#333",
              fontSize: 12,
            },
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },
            // splitLine: {
            //   lineStyle: {
            //     type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
            //     color: "#0096A8",
            //   },
            // },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              // rotate: 45,
              //  interval:0,
              // margin: 8,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                //   color: "#DEE7FF",
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
        ],
        yAxis: [
          {
            max: function (value) {
              //取最大值向上取整为最大刻度
              let num;
              if (value.max < 10) {
                console.log(value);
                num = 10 - value.max;
                return Math.ceil(value.max + num);
              } else if (value.max > 99) {
                console.log(value.max);
                return Math.ceil(value.max / 100) * 100;
              } else {
                console.log(Math.ceil(value.max / 10) * 10);
                return Math.ceil(value.max / 10) * 10;
              }
            },
            type: "value",

            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                // color: "#0096A8",
              },
            },
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              rotate: 0,
              margin: 8,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: "#FF6935",
              },
            },
            label: {
              show: true,
              position: "inside",
              color: "#fff",
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            data: [20, 10, 101, 5, 8, 6, 5, 7, 6, 5, 4, 8],
          },
        ],
      };
      // this.$http
      //   .post(
      //     "main/listCarOut",
      //     this.$qs.stringify({
      //       warningtime: this.fromTimeN,
      //       depart: this.fromDepartN,
      //       cartype: this.cartypeTow,
      //     })
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     //data = res;
      //     option.series[0].data = res.data; //已处理

      //   });
    },
    // 折线图
    getChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 45,
          right: 35,
          bottom: 25,
          top: 25,
        },
        xAxis: {
          name: "月",
          nameTextStyle: {
            color: "#333",
            fontSize: 12,
          },
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0, // 设置y轴刻度的最小值
          max: 5, // 设置y轴刻度的最大值
          splitNumber: 5, // 设置y轴刻度间隔个数
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "男厕",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [34, 90, 20, 20, 20, 12, 11, 14, 90, 21, 23, 46],
            itemStyle: { normal: { label: { show: true } } },
          },
          {
            name: "女厕",
            type: "line",
            lineStyle: {
              color: "#FF8395",
              width: 3,
            },
            color: "#FF8395",
            data: [
              10, 12, 11, 14, 9, 30, 10, 20, 32, 11, 34, 90, 20, 20, 20, 12, 11,
              14, 90, 23, 21, 23, 46, 24,
            ].reverse(),
            itemStyle: { normal: { label: { show: true } } },
            // markLine: {
            //     label:{
            //       position:'middle',
            //       formatter:"5",
            //       fontSize:'16'
            //   },
            //     animation: false,
            //     data: [
            //         {yAxis: 5,}
            //     ],
            //     symbol: "none",
            //     silent: true,
            //     lineStyle: {
            //         type:"solid",
            //         width: 3,
            //         color: "#FF5D31"
            //     }
            // }
          },
        ],
        id: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.WashroomscoreBox {
  width: 100%;
  ::v-deep.el-dialog__header {
    padding: 0;
    background: red;
  }
  ::v-deep .el-dialog__body {
    background: #f2f6fc;
  }
  ::v-deep .el-dialog__footer {
    background: #f2f6fc;
  }
}

.boxTitle {
  font-size: 22px;
  font-weight: bold;
  color: #3b7fff;
}
.box {
  padding-top: 20px;
  height: 500px;
  display: flex;
  flex-direction: column;
  .boxOne {
    display: flex;
    flex: 1;
    .boxOne1 {
      background: #ffffff;
      flex: 1;
      .chart-item {
        // flex: auto;
        width: 100%;
        height: 100%;
      }
    }
    .boxOne2 {
      margin-left: 10px;
      background: #ffffff;
      flex: 1;
    }
  }
  .boxTwo {
    padding-top: 10px;
    display: flex;
    flex: 1;
    .boxTwo1 {
      background: #ffffff;
      flex: 1;
    }
    .boxTwo2 {
      position: relative;
      margin-left: 10px;
      background: #ffffff;
      flex: 2;
    }
  }
}
.boxOneTitle {
  padding: 10px 20px 0px;
  .boxOneTest {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .boxOneNum {
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding-right: 20px;
    color: #f94c1d;
  }
}
.gongce {
  display: flex;
  div {
    color: #333333;
    font-weight: 500;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
.women,
.men {
  display: inline-block;
  width: 25px;
  height: 5px;
  background: #ff9393;
  margin: 0 5px;
}
.men {
  background: #3577e5;
}
// 排名
.paiMing {
  width: 100%;
  display: flex;
  overflow: hidden;
  .paiBox {
    margin: 30px 0px 0px 20px;
    overflow: scroll;
    height: 160px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .paiMian {
      padding: 0px 0px 0px 0px;
      width: 170px;
      height: 50px;
      // width: 50px;
      display: flex;
      align-items: center;

      // flex-direction: column;
      .paiNum {
        margin-left: 5px;
        text-align: center;
        width: 22px;
        height: 20px;
        border-radius: 50%;
        background: #3b7fff;
        color: white;
      }
      .paiText {
        padding: 0px 20px 0px;
      }
    }
  }
}

.dang {
  position: absolute;
  bottom: 8px;
  width: 700px;
  height: 20px;
  background: #ffffff;
}
.shu1,
.shu2,
.shu3 {
  width: 2px;
  height: 140px;
  background: #ebeff5;
  position: absolute;
  top: 25%;
  left: 24%;
}
.shu2 {
  left: 47%;
}
.shu3 {
  left: 70%;
}
@media screen and (max-height: 970px) {
  .tableBox {
    ::v-deep .pagination {
      text-align: center;
      margin: 1% 0px;
    }
  }
}
</style>
